import React from 'react'
import styled from 'styled-components'
import { Card as RCard, Flex, Box, Heading, Text } from 'rebass'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../../components/layout'
import Container from '../../components/container'
import SEO from '../../components/seo'

const Card = styled(RCard)`
  height: 100%;

  h2 {
    color: #023a51;
  }
`

const ServiceCard = styled(Card)`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  @media (min-width: 960px) {
    flex-direction: row;
    text-align: left;
  }
`

const ServiceImage = styled(Img)`
  min-width: 184px;
  margin-right: 0;
  margin-bottom: 24px;

  @media (min-width: 960px) {
    margin-right: 24px;
    margin-bottom: 0;
  }
`

const ServiziPage = ({ data }) => {
  const { nodes } = data.allMarkdownRemark

  const Service = ({ title, description, image, slug }) => (
    <Box width={[1, 1, 1 / 2]} mb={[0, 3, 0]} p={3}>
      <Link to={slug}>
        <ServiceCard
          p={3}
          borderRadius={4}
          boxShadow="1px 1px 4px 1px hsla(0,0%,0%,0.1)"
          minHeight="102px"
        >
          <ServiceImage fluid={image.childImageSharp.fluid} alt={title} />
          <div>
            <Heading mb={2} fontWeight="600" fontSize={3}>
              {title}
            </Heading>
            <Text lineHeight="130%">{description}</Text>
          </div>
        </ServiceCard>
      </Link>
    </Box>
  )

  return (
    <Layout>
      <SEO title="Servizi" />
      <Box p={5} bg="#D9E8ED">
        <Heading
          fontSize={6}
          fontWeight="500"
          textAlign="center"
          fontFamily="Playfair Display, serif"
        >
          Servizi
        </Heading>
      </Box>
      <Container css={{ overflowX: 'hidden' }}>
        <Flex p={3} flexWrap="wrap" mx={-4}>
          {nodes.map(service => {
            const { title, description, image } = service.frontmatter
            const { slug } = service.fields

            return (
              <Service
                title={title}
                description={description}
                image={image}
                slug={slug}
                key={slug}
              />
            )
          })}
        </Flex>
      </Container>
    </Layout>
  )
}

export const ServicesQuery = graphql`
  {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___order] }
      filter: { fileAbsolutePath: { regex: "/servizi/" } }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 320) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default ServiziPage
